<template>
  <div class="result-detail">
    <div class="left">
      <div class="basic-infos card">
        <h2>Basic Information</h2>
        <div class="info-items">
          <div class="item" v-for="item in basicInfo" :key="item.key">
            <div class="key">{{ item.key + " :" }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
          <div class="item">
            <div class="key">Model File Download</div>
            <div class="value">
              <el-icon><bottom /></el-icon> .pkl
            </div>
          </div>
        </div>
      </div>
      <div class="performance card">
        <h2>Model Performance</h2>
        <div class="perform-items">
          <div class="item" v-for="item in modelPerformance" :key="item.key">
            <div class="key">{{ item.key + " :" }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div class="hyper-parameters card">
        <h2>Hyper Parameters</h2>
        <div class="hyper-items">
          <div class="item" v-for="item in hyperParameters" :key="item.key">
            <div class="key">{{ item.key + " :" }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="molecule-img">
        <img id="smilesimg" src="../assets/molecule.png" alt="这是一个分子" />
      </div>
      <div class="picture"></div>
      <div class="description card">
        <div class="descrip-items">
          <div class="item" v-for="item in descriptions" :key="item.key">
            <div class="key">{{ item.key + " :" }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activityApi } from "../api/index";

export default {
  data() {
    return {
      params: {},
      basicInfo: [
        {
          key: "Target Name",
          value: "HT-29",
        },
        {
          key: "Training Dataset Size",
          value: 1800,
        },
        {
          key: "Active Sample Amount",
          value: 1000,
        },
        {
          key: "Inactive Sample Amount",
          value: 800,
        },
      ],
      modelPerformance: [
        {
          key: "TN",
          value: 0.91,
        },
        {
          key: "FP",
          value: 0.9,
        },
        {
          key: "FN",
          value: 0.676,
        },
        {
          key: "TP",
          value: 0.829,
        },
        {
          key: "MCC",
          value: 0.91,
        },
        {
          key: "F1",
          value: 0.9,
        },
        {
          key: "BA",
          value: 0.676,
        },
        {
          key: "AUC",
          value: 0.829,
        },
      ],
      hyperParameters: [
        {
          key: "FPN dropout",
          value: 0.91,
        },
        {
          key: "GAN dropout",
          value: 0.9,
        },
        {
          key: "FP to dimension",
          value: 0.676,
        },
        {
          key: "GAT scale",
          value: 0.829,
        },
        {
          key: "Nheads",
          value: 0.91,
        },
        {
          key: "Nhidden",
          value: 0.91,
        },
      ],
      descriptions: [
        {
          key: "Smile",
          value: 0.91,
        },
        {
          key: "Molecule Weight",
          value: 0.676,
        },
        {
          key: "nHD",
          value: 0.829,
        },
        {
          key: "nHA",
          value: 0.829,
        },
        {
          key: "LogP",
          value: 0.829,
        },
        {
          key: "nRot",
          value: 0.829,
        },
      ],
    };
  },
  mounted() {
    this.params = JSON.parse(this.$route.query.obj);
    this.moleculename = Object.keys(this.params.smilesinfo)[0];
    activityApi
      .getDetailModelInfo({
        mode: this.params.mode,
        model: this.params.targetname,
      })
      .then((res) => {
        this.getParam(res.data);
      });
  },
  methods: {
    getParam(data) {
      //将输入页面传过来的信息进行处理
      this.basicInfo[0].value = data["name"];
      this.basicInfo[1].value = data["total_count"];
      this.basicInfo[2].value = data["activity"];
      this.basicInfo[3].value = data["inactivity"];
      this.modelPerformance[0].value = data["test_tn"];
      this.modelPerformance[1].value = data["test_fp"];
      this.modelPerformance[2].value = data["test_fn"];
      this.modelPerformance[3].value = data["test_tp"];
      this.modelPerformance[4].value = data["test_mcc"];
      this.modelPerformance[5].value = data["test_F1"];
      this.modelPerformance[6].value = data["test_BA"];
      this.modelPerformance[7].value = data["test_auc"];
      this.hyperParameters[0].value = data["hyper_dropout"];
      this.hyperParameters[1].value = data["hyper_dropout_gat"];
      this.hyperParameters[2].value = data["hyper_fp_2_dim"];
      this.hyperParameters[3].value = data["hyper_gat_scale"];
      this.hyperParameters[4].value = data["hyper_nheads"];
      this.hyperParameters[5].value = data["hyper_nhid"];
      this.descriptions[0].value = this.params.moleculename;
      this.descriptions[1].value = this.params.smilesinfo["mw"];
      this.descriptions[2].value = this.params.smilesinfo["hbd"];
      this.descriptions[3].value = this.params.smilesinfo["hba"];
      this.descriptions[4].value = this.params.smilesinfo["logp"];
      this.descriptions[5].value = this.params.smilesinfo["nrot"];
      document
        .getElementById("smilesimg")
        .setAttribute(
          "src",
          "data:image/png;base64," + this.params.smilesinfo["img"]
        );
    },
  },
};
</script>

<style scoped>
.result-detail {
  margin: 20px;
  display: flex;
  justify-content: center;
  /* background: cadetblue; */
}

.left {
  /* background: deepskyblue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  width: 90%;
  border: 2px solid white;
  margin: 10px;
}

.info-items {
  display: flex;
  flex-direction: column;
}

.perform-items,
.hyper-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.perform-items .item,
.hyper-items .item {
  width: 45%;
}

.item {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin: 5px;
}

.molecule-img {
  /* background: forestgreen; */
  text-align: center;
}

.molecule-img img {
  width: 400px;
}

.description {
  /* background: firebrick; */
}
.descrip-items {
  /* background: forestgreen; */
  width: 100%;
}

h2 {
  text-align: center;
}
</style>
